@import "bootstrap_3_3_6/_bootstrap";
@import "font_awesome_4_5_0/font-awesome";
@import "hovereffect";

html,
body {
   margin:0;
   padding:0;
   height:100%;
}

/*Containers*/
.main-container{
	margin:0px;
	padding:0px;
	min-height:100%;
	position:relative;
	@media(min-width:768px){
	    padding-bottom:80px;   /* Height of the footer */
	}
}

.footer-container{
	margin-top:20px;
	bottom:0;
	width:100%;
	padding-top:10px;
	background-color: #eaeaee;
	border-top: 1px solid darken(#eaeaee, 7%);

	@media(min-width:768px){
	    position:absolute;
        height:80px;   /* Height of the footer */
    }
}

.content-container{
	background-color:#fff;
	margin-bottom:20px;
}

.error-container{
	margin-top:20px;
	margin-bottom:20px;
}

.img-container {
  position:relative;
}

.shortlist-toolbar-container{
	padding-top:5px;
	padding-bottom:5px;
	background-color:$gray-lighter;
}

.navigation-container{
    padding:0px;
    @media(max-width: 767px){
        margin-top:10px;
        background-color:#fff;
    }
    @media(min-width: 768px){
        margin:0px;
        background-color: $gray-lighter;
    }
}

.help-container{
    margin-bottom:15px;
}

.summary-subheading{

    color: $gray;
    font-size: 1em;
    font-style: italic;
    font-weight: bold;

    @media(max-width: 767px){
        margin-top: 0px;
    }
    @media(min-width: 768px){
        margin-top: 15px;
        margin-bottom: 30px;
    }
}

.footer{
    /*font-size:0.9em;*/
    text-align: center;

    a{
        color: $brand-primary;
        font-size: 0.9em;

        &:hover{
            color: $brand-danger;
        }
    }
}

.footer .row:not(:first-child){
    margin-top: 5px;
}

.main-header{
    background-color: #fff;
    @media(max-width: 767px){
        padding-left:0px;
        padding-right:0px;
    }
    @media(min-width: 768px){
        padding: 10px 0px 0px 0px;
    }
}

.shortlist-navbar-ul{
    list-style:none;
    float:right;
    margin-bottom:0px;
}

.shortlist-navbar-ul li{
    float:left;
    margin-left:5px;
}

.navbar-brand-image{
    @media (max-width: 767px){
        padding:5px 5px 5px 10px;
    }
    @media (min-width: 768px){
        padding:0;
        margin-right:5px;
        max-width: 100px;
    }
}

.vernon-navbar-container{
    @media (min-width: 768px){
        padding-left:0px;
        padding-right: 0px;
        margin-right: -1px;
    }
}

.vernon-navbar-collapse{
    @media (min-width: 768px){
        padding-left:0px;
        padding-right: 0px;
    }
}

.navbar{
    @media (min-width: 768px){
        margin-bottom:0px !important;
    }

    a{
        text-transform: uppercase;
        font-weight: 700;

        @media(min-width: 768px){
            font-size: 0.9em;
        }
        @media(min-width: 992px){
            font-size: 1em;
        }
    }

    li:first-child a{
        @media(min-width: 768px){
            padding-left: 0;
        }
    }
}

.dropdown-menu li{
    @media(min-width: 768px){
        text-align: center;
    }
}

.dropdown-menu li .btn{
    @media(max-width: 767px){
        margin-left: 20px;
    }
}

.header-left{
    float:left;
    @media (min-width: 768px){
        white-space: nowrap;
    }
}

.header-right{
    float:right;
    @media (min-width: 768px){
        margin: 2px 0 0px 0;
    }
}

.header-right ul{
    list-style: none;
}

.navbar-brand{
    text-decoration:none;
    @media (min-width: 768px){
        padding:0;
        height: 80px;
    }

    img{
        @media(max-width: 767px){
            max-width: 55px;
            margin-left: -5px;
            margin-top: -5px;
        }
    }
}

.vernon-pagination-top{
    display: inline-block;
    width: 100%;
    margin-bottom:20px;
}

.vernon-pagination-bottom{
    @media (max-width: 767px){
        text-align: center;
    }
    @media (min-width: 768px){
        border-top:1px solid $gray-lighter;
        margin-top:20px;
        padding-top: 20px;
    }
}

i.menu-brand{
    @media (max-width: 767px){
        color: #fff;
        font-size:24px;
    }
    @media (min-width: 768px){
        color: $brand-primary;
        font-size:1.0em;
    }
}

b.menu-brand{
    @media (max-width: 767px){
        font-size:24px;
    }
    @media (min-width: 768px){
        color: $gray;
        font-size:36px;
    }
}

.brand-name{
    color: $gray;
    @media (max-width: 767px){
        padding:0px;
        margin:0px;

    }
    @media (min-width: 768px){
        margin: 0px;
        font-size: 1.3em;
        position: absolute;
        top: 12px;
    }
}

/*Search*/
label.search-label-normal{
    font-weight:normal !important;
}

/*Advanced Search*/
.v-adv-search-record-type{

    display: block;
    float: left;
    width: 100%;

    border-bottom: 3px solid $gray;

    @media(max-width: $screen-xs-max){
        margin-bottom: 25px;
    }
    @media(min-width: $screen-sm-min){
        margin-bottom: 25px;
    }
    @media(min-width: $screen-md-min){
        margin-bottom: 25px;
    }

    a {
        display: block;
        float: left;
        padding: 10px 20px;
        margin: 0px;
        color: $gray;

        &.active {
            background-color: $gray;
            color: #fff;
        }
    }
}

button.advanced-search-button{
    float: right;
}

.advanced-search-label{
    text-align:left !important;
}

.advanced-search-label-normal{
    font-weight:normal !important;
}

legend.search-legend{
    width: inherit;
    margin-bottom: 5px;
    border-bottom: none;
    float: left;
    font-weight: bold;
    font-size: inherit;
}

.home-img{
    @media screen and (max-width: 767px){
        max-width:100%;
        margin-bottom:10px;
        margin-top: 10px;
    }
    @media screen and  (min-width: 768px){
        max-width:100%;
        margin:0px;
    }
}

/*Highlight*/
.highlight-desc-container{
    @media(max-width: $screen-sm-max){
        margin-bottom: 20px;
    }
}
.highlights-h2{
    margin-top:0px;
}

.highlights-title:HOVER{
    text-decoration: none !important;
}

.highights-header-left{
    display:block;
    float:left;
    margin-top: -20px;
}

.highights-header-right{
    display:block;
    float:right;
    margin-top:10px;
}

.highlights-img{
    @media screen and (max-width: 299px){
        max-width:100%;
        margin:0px 0px 10px 0px;
    }

    @media screen and (min-width: 300px){
        max-width:100%;
        float:left;
        padding: 5px 15px 0px 0px;
    }

    @media screen and  (min-width: 768px){
        max-width:100%;
        float:left;
        padding: 5px 20px 0px 0px;
    }
}

.highlights-desc-div{
    @media screen and  (min-width: 768px){
        display:block;
        float:right;
        width:76%;
    }
    @media screen and  (min-width: 992px){
        width:60%;
        margin-bottom: 30px;
    }
    @media screen and  (min-width: 1200px){
        width:68%;
        margin-bottom: 30px;
    }
}

/*Error 404 and error page*/
.errorContent {
    position: absolute;
    top: 0;
    left: 0;
    text-align: left;
    width: 100%;
    padding-top:15%;
    font-family:'Lucida Calligraphy', 'Helvetica Neue', Helvetica, Arial, sans-serif;

    @media screen and (max-width: 310px){
        padding-right:20%;
        padding-left:20%;
    }

    @media screen and (min-width: 311px){
        padding-right:20%;
        padding-left:20%;
    }

    @media screen and (min-width: 613px){
        padding-right:25%;
        padding-left:25%;
        font-size:16px;
    }

    @media screen and  (min-width: 768px){
        padding-right:20%;
        padding-left:20%;
        font-size:16px;
    }

    @media screen and  (min-width: 992px){
        padding-left:12%;
    }

    @media screen and  (min-width: 1200px){
        padding-left:20%;
    }
}

.errorContent h1{
    font-weight:bold;
    font-size: 1.5em;
    font-family:'Lucida Calligraphy', 'Helvetica Neue', Helvetica, Arial, sans-serif;

    @media screen and (max-width: 310px){
        margin-bottom:10%;
    }

    @media screen and (min-width: 311px){
        margin-bottom:10%;
    }

    @media screen and  (min-width: 768px){
        margin-bottom:10%;
    }

    @media screen and  (min-width: 1200px){
        margin-bottom:5%;
    }
}

.errorImage{
    max-width:100%;
    margin-top:5%;
}

/*Search result views*/
.nav-pill-vertical-views {

    list-style: none;
    padding: 0px;
    margin-bottom: 0px;

    @media screen and (max-width: 359px){
        width:100%;
    }
    @media(min-width:768px){
        float: right;
        background-color: $gray-lighter;
        border-radius: $border-radius-base;
    }

    li{

        float: left;

        @media screen and (max-width: $screen-xs-max){
            width:100%;
            clear: both;
            margin-top:10px;
            margin-left: 0px !important;
            border-radius: 0px !important;
        }

        &:last-child{
            float: left;

            @media screen and (max-width: $screen-xs-max){
                width:100%;
                clear: both;
                margin-left: 0px !important;
                border-radius: 0px !important;
            }

            a {
                @media(min-width: $screen-sm-min){
                    padding-right: 20px !important;
                }
            }
        }

        &:first-child{
            a{
                @media(min-width: $screen-sm-min){
                    padding-left: 20px !important;
                }
            }
        }

        a{
            border: 0px;
            display: block;
            color: $gray;
            font-size: 0.9em;

            @media(min-width: $screen-sm){
                padding: 5px 10px !important;
            }
            @media(min-width: $screen-sm){
                font-size: 1.2em;
            }

            &:hover{
                color: $brand-primary;
            }
        }

        &.active{
            a{
                color: $brand-primary;
            }
        }
    }
}


.pagination-bottom{
    display:none;
}

.lazy-loading-button{
    margin-left:auto !important;
    margin-right:auto !important;
    display:none;
    margin-top: 20px;
}

.lazy-loading{
    padding-top:20px !important;
    padding-bottom:20px !important;
    display:none;
    margin-left: auto;
    margin-right: auto;
}

.search-well{
    border:none;
    float:left;
    width:100%;
    @media screen and (max-width: 767px){
        padding-top: 10px;
    }
    @media screen and (min-width: 768px){
        border:none;
    }
}

.search-icon{
    display:block;
    float:right;
    width:20%;
    border:none;
    text-align:right;
}

.search-checkbox{
    margin-top:10px;
}

.label-row{
    @media (max-width: 767px){
        margin-bottom:10px;
    }
    @media (min-width: 768px){
        margin-bottom:15px;
    }

}

.label-img{
    max-width:100%;
    width:auto;
    height:auto;
}

.person-masonry-container .lightbox-item {

    background-color: $gray-lighter;
    border: 1px solid #d9d9d9;
    box-shadow: rgb(242, 242, 242) 5px 5px 5px 0px;
    margin: 0 2% 2% 0;
    text-align: center;
    word-wrap: break-word;

    @media screen and (max-width: 549px){
        width:100%;
        margin: 0 2% 2% 0;
    }

    @media screen and (min-width: 550px){
        width:48%;
        margin: 0 2% 2% 0;
    }

    @media screen and (min-width: 1180px){
        width:31%;
        margin: 0 2% 2% 0;
    }

    @media screen and (min-width: 1430px){
        width:23%;
        margin: 0 2% 2% 0;
    }

    @media screen and (min-width: 1820px){
        width: 300px;
        margin: 0 15px 15px 0;
    }

}

.person-masonry-container .lightbox-item a, .lightbox-item .no-link {
    display: block;
    margin-bottom: 5px;
}

.person-masonry-container .lightbox-img {
    display:block;
    max-width:100%;
    margin:0 auto;
}

.person-masonry-container .lightbox-person-desc {

    border-radius: 0px 0px 5px 5px;
    padding: 10px 20px 20px 20px;

    a, .no-link {
        text-align: left;
    }

    a:first-child {
        font-size: 1.1em;
        font-weight: bold;
    }
}

.person-masonry-container .lightbox-item-no-image{
    background-color: $gray-lighter;
    padding-top: 20px !important;
}

.person-masonry-container .lightbox-record-type{
    position: absolute;
    height: 0;
    width: 0;
    bottom: 0px;
    right: 0px;
    border-left: 50px solid transparent;
    border-bottom: 47px solid $gray-light;

    span{
        position: absolute;
        right: 7px;
        bottom: -42px;
        color: #fff;
    }
}

.venue-masonry-container .lightbox-item {
    background-color: $gray-lighter;
    border: 1px solid #d9d9d9;
    box-shadow: rgb(242, 242, 242) 5px 5px 5px 0px;
    margin: 0 2% 2% 0;
    text-align: center;
    word-wrap: break-word;

    @media screen and (max-width: 549px){
        width:100%;
        margin: 0 2% 2% 0;
    }

    @media screen and (min-width: 550px){
        width:48%;
        margin: 0 2% 2% 0;
    }

    @media screen and (min-width: 1180px){
        width:31%;
        margin: 0 2% 2% 0;
    }

    @media screen and (min-width: 1430px){
        width:23%;
        margin: 0 2% 2% 0;
    }

    @media screen and (min-width: 1820px){
        width: 300px;
        margin: 0 15px 15px 0;
    }

}

.venue-masonry-container .lightbox-item a, .lightbox-item .no-link {
    display: block;
    margin-bottom: 5px;
}

.venue-masonry-container .lightbox-img{
    display:block;
    max-width:100%;
    margin:0 auto;
}

.venue-masonry-container .lightbox-venue-desc {
    border-radius: 0px 0px 5px 5px;
    padding: 10px 20px 20px 20px;

    a, .no-link {
        text-align: left;
    }

    a:first-child {
        font-size: 1.1em;
        font-weight: bold;
    }
}

.venue-masonry-container .lightbox-item-no-image{
    background-color: $gray-lighter;
    padding-top: 20px !important;
}

.venue-masonry-container .lightbox-record-type{
    position: absolute;
    height: 0;
    width: 0;
    bottom: 0px;
    right: 0px;
    border-left: 50px solid transparent;
    border-bottom: 47px solid $gray-light;

    span{
        position: absolute;
        right: 7px;
        bottom: -42px;
        color: #fff;
    }
}

.object-masonry-container .lightbox-item {
    border: 1px solid #d9d9d9;
    box-shadow: rgb(242, 242, 242) 5px 5px 5px 0px;
    margin: 0 2% 2% 0;
    text-align: center;
    word-wrap: break-word;
    background-color: $gray-lighter;

    @media screen and (max-width: 549px){
        width:100%;
        margin: 0 2% 2% 0;
    }

    @media screen and (min-width: 550px){
        width:48%;
        margin: 0 2% 2% 0;
    }

    @media screen and (min-width: 1180px){
        width:31%;
        margin: 0 2% 2% 0;
    }

    @media screen and (min-width: 1430px){
        width:23%;
        margin: 0 2% 2% 0;
    }

    @media screen and (min-width: 1820px){
        width: 300px;
        margin: 0 15px 15px 0;
    }
}

.object-masonry-container .lightbox-item a, .lightbox-item span.no-link {
    display: block;
    margin-bottom: 5px;
}

.object-masonry-container .lightbox-img{
    display:block;
    max-width:100%;
    margin:0 auto;
}

.object-masonry-container .lightbox-object-desc {

    border-radius: 0px 0px 5px 5px;
    padding: 10px 20px 20px 20px;

    a, .no-link {
        text-align: left;
    }

    a:first-child {
        font-size: 1.1em;
        font-weight: bold;
    }
}

.object-masonry-container .lightbox-item-no-image{
    background-color: $gray-lighter;
    padding-top: 20px !important;
}

.object-masonry-container .lightbox-record-type{
    position: absolute;
    height: 0;
    width: 0;
    bottom: 0px;
    right: 0px;
    border-left: 50px solid transparent;
    border-bottom: 47px solid $gray-light;

    span{
        position: absolute;
        right: 7px;
        bottom: -42px;
        color: #fff;
    }
}

.detail-row{
    @media screen and (max-width: 359px){
        margin-bottom:10px;
    }

    @media screen and (min-width: 360px){
        margin-bottom:10px;
    }

    @media screen and (min-width: 768px){
        margin-bottom:15px;
    }

    .v-detail-brief_desc{
        font-size: 1.3em;
        padding-bottom: 10px;
        color: $gray-base;
    }
}

.comment-parent{
    margin-bottom:30px;
    margin-top:30px;
}



.xlarge-disclaimer{
    font-style: italic;
    font-size:12px;
    @media screen and (min-width: 360px){
        display:block;
    }
    @media screen and (min-width: 768px){
        display:block;
    }
    @media screen and (min-width: 992px){
        float:right;
    }
}

.xlarge{
    margin-top:10px;

    @media screen and (min-width: 360px){
        display:block;
    }
    @media screen and (min-width: 768px){
        display:block;
    }
    @media screen and (min-width: 992px){
        float:right;
    }
}

.shortlist-row{
    @media screen and (max-width: 359px){
        padding-top:10px;
    }
    @media screen and (min-width: 360px){
        padding-top:10px;
    }
    @media screen and (min-width: 400px){
        padding-top:20px;
    }
    @media screen and (min-width: 768px){
        padding-top:20px;
    }
}

.detail-label{
    display:block;
}

.detail-value{
    display:block;
}

/*
.detailFieldImage{
    @media screen and (max-width: 359px){
        float:left;
        width:100%;
        margin-bottom:20px;
        padding:5px 5px 5px 0px;
    }
    @media screen and (min-width: 360px){
        float:left;
        width:100%;
        margin-bottom:20px;
        padding:5px 5px 5px 0px;
    }
    @media screen and (min-width: 400px){
        width:100%;
        float:right;
        display:block;
        margin-bottom:10px;
        margin-left:10px;

    }
    @media screen and (min-width: 601px){
        width:50%;
        float:right;
        display:block;
        margin-bottom:10px;
        margin-left:10px;
        padding:0px 5px 5px 5px;
    }
    @media screen and (min-width: 768px){
        width:50%;
        float:right;
        margin-bottom:10px;
        margin-left:10px;
    }
    @media screen and (min-width: 992px){
        width:40%;
    }
}*/

.detailFieldContent{
    width:100%;
}

.addthis_inline_share_toolbox a{
    margin-right: 5px !important;
}

/*.detailImageFieldContent{
    @media screen and (max-width: 359px){
        margin-bottom:20px;
    }
    @media screen and (min-width: 400px){
        width:100%;
    }
}*/

.vernon-carousel-left{
	background-image:none !important;
}

.vernon-carousel-right{
	background-image:none !important;
}

.xlarge-pagination{
    list-style: none;
    margin-top: 20px;
}

.xlarge-pagination a{
    margin: 10px;
    padding: 10px;
    border: 1px solid $gray-lighter;
}

.opac-person-share-title {
    @media(min-width: 768px){
        margin-top: 0px;
    }
}

.opac-venue-share-title {
    @media(min-width: 768px){
        margin-top: 0px;
    }
}

.opac-object-share-title, .opac-object-tag-title, .opac-color-title, .opac-related-highlight-title {
    @media(min-width: 601px){
        margin-top: 0px;
    }
}

.opac-object-tag-title:first-child {
    @media(max-width: 767px){
        margin-top: 10px;
    }
}

.opac-related-highlight-tags {
    padding-bottom: 0;

    .multiple{
        padding-bottom: 15px;
    }
}

.opac-related-highlight-tag{

    display: block;
    width: 100%;
    margin-bottom: 0;

    h4{
        display: inline-block;
        margin-top: 0px;
        margin-bottom: 5px;
        color: $link-color;
    }

    img {
        max-width: 75px;
        max-height: 75px;
        text-align: left;
        margin-right: 5px;
        margin-bottom: 5px;
    }
}

.detail-object-tags{
    margin-bottom: 10px;
}

.detail-object-tags .btn{
    margin: 0px 5px 10px 0px;
}

#vernon-carousel{
    @media(min-width: 601px){
       /* margin-bottom: 20px;*/
    }
}

.view-search-box-top{
    padding-top: 5px;
}

.shortlist-action{

}

.opac-home-tag .btn{
    margin-right: 10px;
    margin-bottom: 10px;
}

.object-masonry-container .lightbox-img,
.label-img,
.list-img,
#vernon-carousel img,
.highlights-img,
.related-lightbox img,
.opac-thumbnailer img{
    opacity: 1;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;

}
.object-masonry-container .lightbox-img:hover,
.label-img:hover,
.list-img:hover,
#vernon-carousel img:hover,
.highlights-img:hover,
.related-lightbox img:hover,
.opac-thumbnailer img:hover{
    opacity: 0.8;
}


.related-lightbox{
    display:block;
    width: 100%;
    padding: 20px;
    float: left;
    text-align: center;
    margin: 0px 20px 20px 0px;
    border: 1px solid $brand-success;
    background-color: $gray-lighter;
    border-radius: 5px;
    box-shadow: rgb(242, 242, 242) 5px 5px 5px 0px;
}

.related-lightbox img {
    display: block;
    max-width: 100%;
    margin: 0 auto;
}

.related-description{
    text-align: center;
    margin-top: 20px;
}

.related-lightbox-fixed-height{
    height: 240px;
}

.opac-thumbnailer{
    display: block;
    float: left;
    overflow: hidden;

    @media(max-width: 449px){
        width: 100%;
    }
    @media(min-width: 450px){
        margin: 0 2% 2% 0;
        width: 48%;
    }
    @media(min-width: 768px){
        margin: 0 2% 2% 0;
        width: 31%;
    }
    @media(min-width: 992px){
        margin: 0 2% 2% 0;
        width: 23%;
    }
    @media(min-width: 1200px){
        margin: 0 2% 2% 0;
        width: 23%;
    }
}

.opac-thumbnailer a, .opac-thumbnailer .no-link{
    @media(max-width: 449px){
        display: inline-block;
        float: left;
        width: 100%;
        text-align: center;
    }

    @media(min-width: 450px){
        display: table-cell;
        vertical-align: bottom;
        height: 240px;
        width: 100%;
        text-align: center;
    }
}

.opac-thumbnailer span.desc {
    border-top: 2px solid $gray-lighter;
    display: inline-block;
    height: 65px;
    width: 100%;
    overflow: hidden;
    margin-top: 20px;
    /*margin-left: 10%;
    margin-right: 10%;*/
    padding-top: 10px;
    @media(min-width: 450px){
        min-width: 110px;
    }
}

.opac-thumbnailer img{
    display: inline-block;
    max-width: 100%;
}

.hero{

    display: block;
    background-repeat: no-repeat;
    background-position: center;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    background-size: cover;
    background-color: $brand-primary;

    @media(max-width: 767px){
        /*background-image: url('/assets/images/vernon/home/hero-bg_768.jpg');*/
        padding-top: 20px;
        padding-bottom: 20px;
        margin: 0px;
    }
    @media(min-width: 768px){
        -webkit-background-size: 100%;
        -moz-background-size: 100%;
        background-size: 100%;
        width: 100%;
        /*background-image: url('/assets/images/vernon/home/hero-bg_992.jpg');*/
        padding-top: 30px;
        /*height: 650px;*/
        padding-bottom: 20px;
    }
    @media(min-width: 992px){
        background-attachment: fixed;
        /*background-image: url('/assets/images/vernon/home/hero-bg_1200.jpg');*/
        padding-top: 30px;
        /*height: 625px;*/
    }
    @media(min-width: 1200px){
        /*background-image: url('/assets/images/vernon/home/hero-bg_1800.jpg');*/
        padding-top: 30px;
        /*height: 700px;*/
    }
    @media(min-width: 1800px){
        /*background-image: url('/assets/images/vernon/home/hero-bg.jpg');*/
        padding-top: 30px;
        /*height: 700px;*/
    }

    .caption{

        font-style: italic;
        margin-top: 20px;
        display: block;
        float: left;

        @media(min-width: 768px){
            font-size: 0.85em;
        }
        @media(min-width: 992px){
            font-size: 1em;
        }
        @media(min-width: 1200px){
            font-size: 1em;
        }
    }

    a{
        color: #fff;
        text-decoration: underline;
    }
}

.hero h1 {
    color: #fff;
    @media(max-width: 767px){
        font-weight: bold;
        font-size: 2.2em;
    }
    @media(min-width: 768px){
        font-weight: bold;
        margin-bottom: 30px;
    }
}

.hero p {
    color: #fff;

    @media(min-width: 768px){
        font-size: 1.1em;
    }
    @media(min-width: 992px){
        font-size: 1.2em;
    }
    @media(min-width: 1200px){
        font-size: 1.225em;
    }
}

.hero .btn{
    margin-right: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.hero .hero-scroll-down {
    display: none;
}

.hero .hero-scroll-down:hover{
    opacity: 0.8;
}

.hero .hero-scroll-down .fa{
    display: block;
    color: #fff;
    opacity: 0.7;
    @media(max-width: 767px){
        text-align: center;
        margin-top: 40px;
    }
    @media(min-width: 768px){
        position: absolute;
        left: 350px;
    }
    @media(min-width: 992px){
        left: 450px;
    }
    @media(min-width: 1200px){
        left: 560px;
    }
}

h1.loud{
    text-align: center;
    font-size: 2.8em;
    margin-top: 50px;
    margin-bottom: 20px;
}

.hero .page-header{
    margin: 0px;
    border-bottom: 0px;
}

.hero .page-header small{
    display: block;
    color: #ddd;
    font-weight: bold;
    margin-top: 10px;
}

.home-collections {
    margin-bottom: 40px;
}

.home-about img, .home-collections img{
    max-width: 100%;

    @media(max-width: 767px){
        display: block;
        margin: 0 auto;
    }
}

.text-image{
    width: 100%;
}

.text-image a{
    display: block;
}

.text-image img{
    opacity: 0.9;
}

.text-image a:hover{
    background-color: black;
}

.text-image img:hover{
    opacity: 1;
}

.text-image h2 {
    @media(max-width: 767px){
        margin-top: -100px;
        margin-bottom: 100px;
        text-align: center;
    }
    @media(min-width: 768px){
        position: absolute;
        top: 150px;
        left: -5px;
        font-size: 1.2em;
    }
    @media(min-width: 992px){
        top: 200px;
        left: 0px;
        font-size: 1.6em;
    }
    @media(min-width: 1200px){
        top: 250px;
        left: 0px;
        font-size: 1.8em;
    }
}

.text-image h2 span {
   color: #fff;
   padding: 5px 15px 5px 15px;
   border-radius: 2px;

   -webkit-box-shadow: 10px 10px 25px 2px rgba(0,0,0,0.25);
   -moz-box-shadow: 10px 10px 25px 2px rgba(0,0,0,0.25);
   box-shadow: 10px 10px 25px 2px rgba(0,0,0,0.25);
}

.text-image h2 span.primary{
    background-color: $brand-primary;
}

.text-image h2 span.warning{
    background-color: $brand-warning;
}

.text-image h2 span.danger{
    background-color: $brand-danger;
}

.footer .version, .footer .technology{
    @media(min-width: 768px){
        float:right;
    }
}

.navigation-search{
    @media(min-width: 768px){
        width: 30%;
    }
    @media(min-width: 992px){
        width: auto;
    }
}

.navigation-search .form-control, .navigation-search .form-control:hover, .navigation-search .form-control:focus{
    border: 1px solid $gray-base;
}

.comment-quote{
    padding: 10px 30px;
}

.comment-quote:before{
    top: 10px;
    left: 15px;
    position: absolute;
    background-position: 0 0;
    background-image: url('/assets/images/vernon/quote.png');
    width: 20px;
    height: 20px;
    content: '';
}
.comment-quote:after{
    bottom: 0;
    right: 15px;
    position: absolute;
    background-position: -20px 0;
    background-image: url('/assets/images/vernon/quote.png');
    width: 20px;
    height: 20px;
    content: '';
}

.comment-quote p {
    font-style: italic;
}

.comment-quote p.posted{
    font-style:normal;
    font-size: 0.8em;
    text-align: right;
}

.comment-quote p.by{
    font-style: normal;
    text-align: right;
}

.comment-quote p.by span{
    text-transform: capitalize;
}

.no-comment-holder{
    margin: 20px 0px;
    text-align: center;
    font-style: italic;
    max-width: unset;
}

.comment-recent-heading{
    font-weight: bold;
}

.comment-recent{
    padding: 5px 20px;
    @media(min-width:992px){
        margin-right: 40px;
    }
}

.comment-recent:before{
    top: 50px;
    left: 15px;
    position: absolute;
    background-position: 0 0;
    background-image: url('/assets/images/vernon/quote_small.png');
    width: 10px;
    height: 10px;
    content: '';
}
.comment-recent:after{
    bottom: 15px;
    position: absolute;
    background-position: -30px 0px;
    background-image: url('/assets/images/vernon/quote_small.png');
    width: 10px;
    height: 10px;
    content: '';
    @media(max-width:991px){
        right: 10px;
    }
    @media(min-width:992px){
        right: 55px;
    }
}

.comment-recent p.by{
    font-style: normal;
    text-align: right;
}

.comment-recent p.text{
    font-style: italic;
}

.comment-recent p.by span{
    text-transform: capitalize;
}

#addCommentsCollapseAnchor{
    font-weight: bold;
}

.v-side-refine{

    text-align: center;
    background-color: $brand-primary;
    padding: 7px 10px;
    width: 100%;
    color: #fff;
    font-weight: bold;

    @media(max-width: $screen-sm-max){
        display: block;
    }
    @media(min-width: $screen-md){
        display: none;
    }

    &:hover{
        cursor: pointer;
    }
}

.v-facet{

    @media(max-width: $screen-sm-max){
        display: none;
    }
    @media(min-width: $screen-md){
        display: block !important;
    }

    .v-facet-title{
        padding: 0 15px;
        width: 100%;
        color: $gray-base;
        font-weight: bold;
        background-color: $gray-lighter;
        /*color: #fff;*/
    }

    .v-facet-content{
        background-color: $gray-lighter;

        .fa-check-square-o{
            color: $brand-primary;
        }
    }

    .v-facet-more-button{
        margin-top: 10px;
    }

    .facet-value{
        &:before{
            content: "("
        }
        &:after{
            content: ")"
        }
    }

    a{
        color: $gray-base;

        &:hover{
            color: $brand-primary;
        }
    }

    a.active{
        color: $gray-base;

        &:hover{
            color: $gray-darker;
        }
    }

    ul{
        margin-bottom: 0;
        padding: 5px 15px 20px 15px;
        list-style: none;
        border-bottom: 1px solid $gray-lighter;

        li{
            margin-bottom: 5px;
            text-transform: capitalize;
        }
    }

    .v-facet-range{

        padding: 15px 15px 20px 15px;
        border-bottom: 1px solid $gray-lighter;

        .from{
            width: 26%;
            display: inline;
        }

        .separator{
            text-transform: capitalize;
            margin: 0px 4px;
        }

        .to{
            width: 26%;
            display: inline;
        }

        .btn{
            margin-left: 5px;
        }
    }

    .v-color-title{
        text-transform: capitalize;
    }

    .v-facet-color{
        ul{
            display: inline-block;
            width: 100%;

            li{
                display: block;
                float: left;
            }

        }

        a {
            display: block;
            width: 30px;
            height: 30px;
            margin-right: 10px;
            margin-bottom: 5px;
            padding: 2px;
            border: 1px solid $gray-lighter;
            border-radius: 2px;
        }

        .v-facet-color-swatch{
            width: 100%;
            height: 100%;
            display: block;


            span{
                display: block;
                margin: 0 auto;
                width: 30px;
                height: 30px;
            }
        }

        .active {
            border: 1px solid $brand-primary;
        }

        .v-color-more{
            width: 100%;
        }
    }

    .v-remove-all-filters{
        text-transform: uppercase;
        font-size: 0.8em;
        display: inline-block;
        color: $brand-primary;
        font-weight: bold;
        &:before{
            content: "[ "
        }
        &:after{
            content: " ]"
        }
    }

}

.v-side-search{

    background-color: $gray-lighter;
    padding-bottom: 10px;

    @media(max-width: $screen-sm-max){
        display: none;
        margin-top: 15px;
    }
    @media(min-width: $screen-md){
        display: block !important;
    }

    .v-side-search-title{
        padding: 7px 0px;
        width: 100%;
        /*background-color: $brand-primary;*/
        /*color: $brand-primary;*/
        font-weight: bold;
    }

    .v-side-refine-title{
        padding: 10px 15px 7px 15px;
        width: 100%;
        font-weight: bold;
        font-size: 1.2em;
    }

    .v-side-search-content{
        padding: 0px 0px 15px 0px;
        /*margin-bottom: 15px;*/
        border-bottom: 1px solid $gray-lighter;
    }

    .v-search-checkbox{
        margin-top: 5px;
    }

    input{
    }

    input, .btn{
        /*border-radius: 0px;*/
    }

    .btn{
        margin-top: 10px;
    }
}

input.faChkSqr {
  visibility: hidden;
  margin-right: 5px;
}

input.faChkSqr:checked:after, input.faChkSqr:after {
  visibility: visible;
  font-family: FontAwesome;
  position: relative;
  top: -2px;
  left: 0px;
  display: inline-block;
  margin-right: 2px;
}

input.faChkSqr:checked:after {
  content: '\f046';
}

input.faChkSqr:after {
  content: '\f096';
}

.v-sort-view-container{

    @mixin option-label(){
        font-weight: bold;
    }

    display: block;
    float: left;
    width: 100%;
    margin-bottom: 20px;
    border-bottom: 1px solid $gray-lighter;
    padding: 10px 0px 15px 0px;

    @media(min-width: $screen-md){
        /*border-top: 5px solid $brand-primary;*/
    }

    .v-more-options-holder{

        @include option-label();

        display: block;
        margin-top: 10px;

        @media(max-width: 549px){
            float: left;
            width: 100%;
        }
        @media(min-width: 550px){
            float: left;
            clear: both;
        }

        &:hover{
            cursor: pointer;
        }

        .fa-close{
            margin-left: 4px;
        }

        .fa-chevron-down{
            margin-left: 1px;
        }

    }

    .v-view{

        display: block;

        @media(max-width: $screen-xs-max){
            float: left;
            width: 100%;
            padding-top: 15px;
        }
        @media(min-width: $screen-sm){
            float: right;
        }

        .v-label{

            @include option-label();

            @media(min-width: $screen-sm){
                float: left;
                margin-right: 10px;
                padding-top: 7px;
            }
        }
    }

    .v-current-filters{

        display: block;
        clear: both;
        float: left;
        margin-top: 10px;
        width: 100%;

        .v-label{
            @include option-label();
            float: left;
            width: 100%;
            text-transform: capitalize;
        }

        .v-current-filter-item{

            list-style: none;
            overflow: hidden;
            padding-left: 0px;
            margin: 0px;
            float: left;

            li{
                float: left;
                margin-right: 10px;
                margin-bottom: 10px;

                @media(max-width: 500px){
                    width: 100%;
                }
                span.filter-term {
                    padding: 2px 10px 2px 10px;
                    color: #fff;
                    background: $brand-primary;
                    border-radius: 3px 0px 0px 3px;
                    text-transform: capitalize;

                    @media(max-width: 500px){
                        width: 35%;
                        overflow: hidden;
                        white-space:nowrap;
                        text-overflow: ellipsis;
                    }
                }

                span.filter-label {
                    color: $gray-base;
                    padding: 2px 10px 2px 10px;
                    background: $gray-lighter;
                    text-transform: capitalize;

                    @media(max-width: 500px){
                        width: 40%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space:nowrap;
                    }
                }

                a.filter-close{
                    padding: 2px 10px 2px 10px;
                    color: #fff;
                    background: $gray-light;
                    border-radius: 0px 3px 3px 0px;
                    text-align: center;

                    @media(max-width: 500px){
                        width: 15%;
                    }

                    &:hover {
                        background: $brand-danger;
                    }

                }

                a.filter-close, span.filter-term, span.filter-label {
                    text-decoration: none;
                    position: relative;
                    font-size: 0.8em;
                    display: block;
                    float: left;
                }
            }
        }
    }

    .v-more-options{

        width: 100%;
        float: left;
        background: $gray-lighter;
        padding: 0px 15px 10px;
        border-radius: $border-radius-base;

        .collapse{
            display: none !important;
        }

        .v-add-all-shortlist{

            display: block;
            float: left;
            padding-top: 7px;

            @media(max-width: 549px){
                width: 100%;
            }

            .v-label{
                @include option-label();
            }

            ul{
                padding: 0px;
                margin: 0px;
                list-style: none;
            }

            li{
                @media(max-width: $screen-xs-max){
                    margin-top: 10px;
                }
            }

            .v-shortlist-action{
                font-size: 0.9em;
                color: $gray-base;

                &:hover{
                    color: $brand-primary;
                    cursor: pointer;
                }
            }
        }

    }

    .v-sort{

        float: left;
        padding: 0px;

        @media(max-width: 549px){
            width: 100%;
        }

        label{
            padding: 7px 10px 0px 0px;
            float: left;
            @include option-label();
        }

        select{
            width: auto;
            display: inline;
            float: left;
        }

        .fa-sort-alpha-asc, .fa-sort-alpha-desc {
            display: block;
            float: left;
            margin-left: 10px;
            margin-top: 10px;
            font-size: 1.2em;
        }

        .hidden{
            display: none;
        }

        #sortDirection{
            color: $gray-base;

            &:hover{
                color: $brand-primary;
            }
        }
    }
}

.shortListNavigationButtonRow{
    .btn{

        border: 0px;
        background-color: transparent;

        .fa{
            font-size: 1.6em;
        }

        .fa-star{
            color: #EED20D;
        }

        &:hover, &:focus, &:active{
            background-color: transparent;
            color: $gray-base;
        }
    }
}

.v-facet-modal{

    .modal-loader{
        display: block;
        margin: 30px auto !important;
        width: 65px;
    }

    .modal-header {
        background-color: $brand-primary;
        border-bottom: 1px solid $brand-primary;

        h2{
            color: #fff;
        }
    }

    .v-facet-more-content{

        ul.v-facet-more-terms {
            list-style: none;
            padding: 0px;
            text-transform: capitalize;

            li{
                margin-bottom: 5px;

                @media(min-width: $screen-md){
                    width: 50%;
                    float: left;
                }
            }

            a{
                color: $gray-base;

                &:hover{
                    color: $brand-primary;
                }
            }

            .fa-check-square-o{
                color: $brand-primary;
            }
        }

        .facet-value{
            &:before{
                content: "("
            }
            &:after{
                content: ")"
            }
        }

        .facet-no-records{
            text-align: center;
            font-weight: bold;
            font-style: italic;
        }
    }

    .v-facet-pagination-item{
        cursor: pointer;
    }
}

.v-show-more{
    padding: 0px;
    color: $brand-danger;
}

.opac-image-grid {
    display:block;

    .o-sizer {
        background-color: transparent !important;
        border: 0px solid #d9d9d9 !important;
        box-shadow: none !important;
    }

    .o-sizer,
    .o-item{
        margin: 0 2% 2% 0;
        text-align: center;
        word-wrap: break-word;
        background-color: $gray-lighter;
        border: 1px solid #d9d9d9;
        box-shadow: rgb(242, 242, 242) 5px 5px 5px 0px;

        @media screen and (max-width: 549px){
            width:100%;
        }

        @media screen and (min-width: 550px){
            width:48%;
        }

        @media screen and (min-width: 768px){
            width:31%;
        }

        @media screen and (min-width: 992px){
            width:31%;
        }

        @media screen and (min-width: 1200px){
            width:23%;
        }

        a{
            display: block;
        }

        .o-image{
            max-width: 100%;
            display: block;
            margin: 0 auto;
        }

        .o-desc{
            width: 100%;
            display: block;
            padding: 10px;
            @media(max-width: 549px){
                text-align: center;
            }
            @media(min-width: 550px){
                text-align: left;
            }
        }

        .o-desc-no-image {
            margin: 10px;
        }

        .o-title {
            font-size: 1.1em;
            font-weight: bold;
        }

        .fa-5x {
            padding: 20px 0px 20px 10px;
            width: 100%;
            background-color: #fff;
        }
    }
}

.v-search-text{
    margin-top: 10px;
}

.v-search-for{
    margin-top: 10px;
}

.v-search-images{
    margin-top: 10px;
}

.v-advanced-search-link{
    margin-top: 0px;
}

.v-header-facet{

    display: block;
    float: left;
    width: 100%;
    margin: 0px 0px 10px 0px;
    padding: 0px;
    list-style: none;
    border-bottom: 3px solid $gray-base;

    li{
        display: block;
        float: left;
    }

    a, .active {
        display: block;
        padding: 10px 15px;
        text-align: center;
        color: $gray;
    }

    .active {
        color: #fff;
        background-color: $gray-base;
    }

    .count{
        min-width: 25px;

        /*&:before{
            content: "(";
        }
        &:after{
            content: ")";
        }*/
    }
}

.v-color-block{

    display: inline-block;
    width: 100%;

    a {
        float: left;
        border: 1px solid $gray-lighter;
        width: 30px;
        height: 30px;
        margin-right: 7px;
        background-color: #fff;
        padding: 2px;
        border-radius: 2px;
    }

    span{
        display: block;
        width: 100%;
        height: 100%;
    }
}

.v-color-block-extra-top-margin{
    margin-top: 10px;
}

.v-color-block-extra-large{
    margin-bottom: 0;
    a {
        width: 50px;
        height: 50px;
        padding: 3px;
        margin-right: 10px;
        margin-bottom: 10px;
    }
}

.v-more-facet-block{
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 2px;
}

.nav-collapse-button{
    background-color: $gray-base;
}

.v-hero-tile-container {
    display: block;
    margin-bottom: 15px;
    position: relative;
    background-color: $brand-primary;
    height: 400px;

    @media(min-width: 768px){
        height: 300px;
    }
    @media(min-width: 992px){
        height: 350px;
    }
    @media(min-width: 1200px){
        height: 400px;
    }

    &:hover{
        opacity: 0.7;
    }

    .v-hero-tile {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: left top;
        background-size:cover;
        position: relative;
        display: block;
    }

    h3{
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        text-align: center;
        background-color: $gray-light;
        color: $gray-base;
        margin: 0px;
        padding: 15px;
        opacity: 0.9;
        font-family: $font-family-sans-serif;
        font-weight: 600;
    }

}

.j-featured-container{
    margin-top: 15px;
    max-width: 2400px;
}

.j-hero-tile-container {
    display: block;
    position: relative;
    background-color: transparent;
    margin-bottom: 30px;

    &:hover{
        opacity: 0.7;
    }

    .hero-tile {
        width: 100%;
        height: 0;
        padding-top: 75%; /*Calculated based on an aspect ratio of 4:3 i.e (576/768 * 100)*/
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        position: relative;
        display: block;
    }

    h3{
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        text-align: center;
        background-color: $brand-primary;
        color: #fff;
        margin: 0px;
        padding: 10px;
        opacity: 0.9;

        @media(max-width: 1199px){
            font-size: 1em;
        }
        @media(min-width: 1200px){
            font-size: 1.2em;
        }
    }

}

.j-std-highlight{

    display: block;
    float: left;
    margin-bottom: 15px;
    padding-bottom: 30px;
    border-bottom: 1px dashed $hr-border;
    width: 100%;

    @media(min-width: 768px){
        height: 420px;
        width: 46%;
        max-width: 768px;
        margin-right: 2%;
        overflow: hidden;
    }

    @media(min-width: 992px){
        height: 350px;
    }

    @media(min-width: 1200px){
        height: 330px;
    }
    @media(min-width: 1500px){
        height: 350px;
    }

    h2{
        margin-top: 15px;
    }

    p{
        margin-bottom: 0px;
    }

    .highlights-img-container{
        @media(max-width: 767px){
            width: 100%;
            display: block;
            float: left;
        }
    }

    img {
        display: block;

        @media(max-width: 767px){
            max-width: 100%;
            margin-bottom: 20px;
        }
        @media(min-width: 768px){
            max-height: 150px;
            max-width: 150px;
            margin-right: 15px;
            margin-bottom: 20px;
        }
        @media(min-width: 768px){
            max-width: 200px;
            max-height: 200px;
        }
        @media(min-width: 1200px){
            max-width: 250px;
            max-height: 250px;
        }
        @media(min-width: 1500px){
            max-width: 275px;
            max-height: 275px;
        }
    }
}

.j-std-highlight-border{
}

.v-top-search{
    -webkit-box-shadow: rgba(0,0,0,0.2) 0px 2px 5px;
    -moz-box-shadow: rgba(0,0,0,0.2) 0px 2px 5px;
    box-shadow: rgba(0,0,0,0.2) 0px 2px 5px;
    color: #fff;

    @media(max-width: $screen-sm-max){
        margin-top: 15px;
        display: block !important;
        background-color: $brand-primary;
        margin-top: 15px;
        margin-bottom: 15px;
        padding: 15px;
    }
    @media(min-width: $screen-md){
        display: block !important;
        background-color: $brand-primary;
        margin-top: 15px;
        margin-bottom: 15px;
        padding: 15px;
    }

    .form-control{
        border: 1px solid;
    }

    .v-top-search-title{
        width: 100%;
        font-weight: bold;
        margin-bottom: 0px;

        @media(max-width: $screen-sm-max){
            font-size: 1.2em;
        }
        @media(min-width: $screen-md){
            font-size: 1.4em;
        }

        small{

            font-size: 0.75em;
            span{

            }
        }
    }

    .v-top-search-read-more{
        width: 100%;
        font-size: 1.0em;

        .v-top-search-link{
            border-bottom: 1px solid #fff;
            &:hover{
                cursor: pointer;
            }
        }
    }

    .v-search-checkbox{
        margin-top: 10px;
    }

    /*.btn{
        margin-top: 10px;
    }*/

    img{
        float: left;
        margin-right: 15px;
        margin-bottom: 15px;

        @media(max-width: 350px){
            display: none;
        }

        @media(min-width: 351px){
            max-width: 90px;
        }

        &:hover{
            cursor: pointer;
        }
    }
}

.j-detail-image-title-container{
    display: inline-block;
    width: 100%;
    background-color: $gray-lighter;

    @media(max-width: 767px){
        margin: 15px 0px 20px 0px;
    }
    @media(min-width: 768px){
        margin-top: 20px;
        margin-bottom: 20px;
    }
    @media(min-width: 1200px){
    }

    .j-title{
        display: block;
    }

    .j-image{
        float: left;
        display: block;

        @media(max-width: 767px){
            max-width: 100%;
            margin: 0px 15px 20px 15px;
        }
        @media(min-width: 768px){
            max-width: 40%;
            margin: 15px 30px 15px 30px;
        }
        @media(min-width: 992px){
            max-width: 50%;
            margin: 0px 30px 0px 0px;
        }
        @media(min-width: 1550px){
            max-width: 100%;
            margin-right: 75px;
        }
    }

    .j-desc{
        margin-bottom: 30px;
        max-width: 2000px;

        @media(max-width: 767px){
            font-size: 1.2em;
            padding: 10px 15px 0px 15px;
        }
        @media(min-width: 768px){
            font-size: 1.2em;
            padding: 10px 30px 0px 30px;
        }
        @media(min-width: 992px){
            padding: 10px 25px 0px 25px;
        }
        @media(min-width: 1200px){
            padding: 10px 50px 0px 50px;
        }
        @media(min-width: 1550px){
            font-size: 1.6em;
        }
    }

    h1{
        max-width: 2000px;
        font-weight: bold;
        text-align: left;

        @media(max-width: 767px){
            font-size: 1.8em;
            line-height: 1.6;
            padding: 15px 15px 0px 15px;
            margin: 0px;
        }
        @media(min-width: 768px){
            font-size: 1.8em;
            line-height: 1.6;
            padding: 10px 30px 0px 30px;
            margin: 0px;
        }
        @media(min-width: 992px){
            padding: 10px 25px 0px 25px;
        }
        @media(min-width: 1200px){
            padding: 10px 50px 0px 50px;
        }
        @media(min-width: 1550px){
            font-size: 3em;
            padding: 40px 50px 0px 50px;
        }
    }

    h2{
        margin-top: 0px;
        margin-bottom: 20px;

        @media(max-width: 767px){
            font-size: 1.6em;
            padding: 10px 15px 0px 15px;
        }
        @media(min-width: 768px){
            font-size: 1.6em;
            padding: 10px 30px 0px 30px;
        }
        @media(min-width: 992px){
            padding: 10px 25px 0px 25px;
        }
        @media(min-width: 1200px){
            padding: 10px 50px 0px 50px;
        }
        @media(min-width: 1550px){
            font-size: 2.2em;
        }
    }

    .j-inverted-button{
        border: 1px solid #000;
        color: #000;

        &:hover{
            color: #fff;
        }
    }

    .btn{
        margin-top: 20px;

        @media(min-width: 1550px){
            margin-top: 30px;
        }
    }
}

.j-detail-right-pane{
    background-color: $gray-lighter;
    padding: 30px 15px 20px 15px;
}

.j-back-link{
    margin-top: 22px;
    display: block;
}

.j-easy-nav-container{

    display: block;
    width: 100%;

    @media(max-width: 767px){
        margin-top: 10px;
    }

    @media(min-width: 768px){
        margin-top: 10px;
        margin-bottom: 30px;
    }

    a {
        vertical-align: middle;
        color: #fff;
        font-size: 1.2em;
        margin-right: 60px;
        text-decoration: none;

        @media(max-width: 767px){
            height: auto;
            padding: 10px 0px;
            display: block;
        }

        @media(min-width: 768px){
            white-space: nowrap;
            width: auto;
            height: auto;
        }
    }

    span {
        margin-bottom: 10px;
        margin-right: 10px;
        margin-top: 20px;

        @media(max-width: 767px){
            font-size: 30px;
        }
        @media(min-width: 768px){
            font-size: 50px;
        }
    }

    .j-hero-easy-nav{

        display: block;
        float: left;
        margin: 10px 40px 10px 0px;

        @media(max-width: 767px){
            width: 100%;
            height: auto;
        }

        @media(min-width: 768px){
            width: auto;
            height: auto;
        }


    }
}

.j-color-container{
    margin-top: 20px;

    .j-swatch{
        display: block;
        border: 2px solid $gray-lighter;
        width: 60px;
        height: 60px;
        border-radius: 4px;
        float: left;
        margin-right: 25px;
        margin-top: 10px;
        margin-bottom: 10px;

        span{
            display: block;
            width: 100%;
            height: 100%;
        }
    }
}

/*Vernon Customizations for PRINT media*/
@media print{
	.footer-container{border-top:1px solid $gray-lighter;}
	.navigation-container{display:none;}
	.lightbox-img{width:30%;}
	.lightbox-item{word-wrap: break-word !important;}
	.detailFieldContent{margin-top:20px;}
	a[href]:after {content: none;}/*Remove the url added by bootstrap for every link*/
}
